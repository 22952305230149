import { Button } from '@abyss/web/ui/Button';
import React, { useContext } from 'react';
import { config } from '@abyss/web/tools/config';
import axios from 'axios';
import { useTranslation } from '../../abyss/web/hooks/useTranslation';
import { TranslationContext } from '../../abyss/web/hooks/useTranslation/TranslationContext';
import { useRouter } from '@abyss/web/hooks/useRouter';

export const ChoosePCP = (props) => {
  const { payload } = props;
  const RALLY_HEALTH_URL = config('RALLY_HEALTH_URL');
  const DEV_URL= config('DEV_URL');
  const targetURL = DEV_URL + config('API_RALLY_CALLBACK');
  const API_MEMBER_URL = DEV_URL+ config('API_MEMBER_PATH');
  const API_ERROR_LOG_URL = DEV_URL+ config('API_RALLY_ERROR_LOG');
  const clientProdCode = payload?.ndbLob;
  const client = 'earlyPayIFP';
  const lineOfBusiness = 'EXCHANGE';
  const date = payload?.coverageEffectiveDate;
  const year = date?.substring(6, 10);
  const zip = payload?.zipCode;
  const currentLanguage = useContext(TranslationContext);
  const {navigate} = useRouter();
  const requestData = {
    zip,
    year,
    clientProdCode,
    client,
    lineOfBusiness,
    targetURL,
  };

  const postMemberData = async () => {
  const txnid = crypto.randomUUID() + currentLanguage.locale;
  const transactionId = txnid;
  const language = currentLanguage.locale;
  const {
    exchangeId,
    gated,
    zipCode,
    stateCode,
    ndbLob,
    qhpId,
    coverageEffectiveDate,
    virtualFirst,
    planId,
  } = payload;

  const memeberPayload = {
    exchangeId,
    gated,
    zipCode,
    stateCode,
    ndbLob,
    qhpId,
    coverageEffectiveDate,
    virtualFirst,
    language,
    planId,
    transactionId,
  };

   axios
      .post(API_MEMBER_URL, memeberPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${RALLY_HEALTH_URL}/${txnid}`;
        const formInput = document.createElement('input');
        formInput.type = 'hidden';
        formInput.name = 'json';
        formInput.value = JSON.stringify(requestData);
        form.appendChild(formInput);
        document.body.appendChild(form);
        form.submit();
      })
      .catch((exception) => {
        try{
          if(exception.response.data.includes('errorOccuredIn')){
            navigate('/error');
          }
        }catch(excep){
          axios.post(API_ERROR_LOG_URL, {transactionId}, {
            headers: {
              'Content-Type': 'application/json',
            },
          }).catch(()=>{
            navigate('/error');
          });
        }           
    });
  };
  const g = useTranslation('gated');
  const n = useTranslation('nongated');
  
  return (
    <div>
      <Button
        onClick={() => {
          return postMemberData();
        }}
      >
        {payload?.gated === 'Y' ? g('button') : n('button')}
      </Button>
      <br />
      <br />
    </div>
  );
};
