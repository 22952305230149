import React from 'react';
import { styled } from '@abyss/web/tools/styled';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Heading } from '@abyss/web/ui/Heading';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { useTranslation } from '../../abyss/web/hooks/useTranslation';
import { Header } from '../Header';

const TitleContainer = styled('div', {
  padding: '$xl',
  backgroundColor: '$primary1',
});

const TitleMargin = styled('div', {
  marginLeft: 35,
});

const BodyContainer = styled('div', {
  width: '59%',
  marginLeft: 80,
});

const TextContainer = styled('div', {
  width: '63%',
});

const Container = styled('div', {
  backgroundColor: '$primary2',
  height: '100vh',
});

export const Thankyou = () => {
  const t = useTranslation('thankyou');
  const {getRouteParams} = useRouter();
  const { token } = getRouteParams();
  let payload = tokenizer.parse(token);

  if (!payload) {
    payload = tokenizer.parse(localStorage.getItem('payload_ty'));
  }
  return (
    <Container>
      <Header payload={payload} />
      <TitleContainer>
        <TitleMargin>
          <Heading size={30} color="$white">
            {t('title')}
          </Heading>
        </TitleMargin>
      </TitleContainer>
      <Layout.Space space={60} />
      <BodyContainer>
        <Layout.Group space={20}>
          <IconBrand icon="checkmark" size={60} />
          <Heading offset={2}>{t('confirm')}</Heading>
        </Layout.Group>
        <Layout.Space space={55} />
        <Text fontWeight="$bold" color="$black">
          {t('heading')}
        </Text>
        <Layout.Space space={40} />
        <Layout.Group alignItems="start" space="$xs">
          <IconBrand icon="stethoscope" variant="twotone" size={60} />
          <div>
            <Text color="$primary1" size="22px" fontWeight="$bold">
              Dr. {payload?.providers[0]?.providerName?.firstName}{' '}
              {payload?.providers[0]?.providerName?.lastName}{' '}
              {payload?.providers[0]?.providerName?.degree}
            </Text>
            <div>
              <Text color="$accent1" size="$lg" fontWeight="$bold">
                {payload?.providers[0]?.medicalGroup?.medicalGroupDesc}
              </Text>
            </div>
            <Text size="$lg">
              <div>{payload?.providers[0]?.providerAddress?.street1}</div>
              <div>
                {payload?.providers[0]?.providerAddress?.city},{' '}
                {payload?.providers[0]?.providerAddress?.state}{' '}
                {payload?.providers[0]?.providerAddress?.zip}
              </div>
            </Text>
          </div>
        </Layout.Group>
        <Layout.Space space={40} />
        <Heading offset={2}>
            {t('nextLabel')}
        </Heading>
        <TextContainer>
          <Text color='$gray6'>{t('nextInfo')}</Text>
        </TextContainer>
      </BodyContainer>
      <script type="text/javascript">_satellite.pageBottom();</script>
    </Container>
  );
};